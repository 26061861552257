<script lang="ts">
  import SvelteSeo from 'svelte-seo';

  type SEO = {
    title: string | undefined;
    description: string | undefined;
    image?: string;
    keywords?: string;
    path: string;
    follow?: boolean;
    index?: boolean;
  };

  export let seo: SEO;
  const {
    title,
    description,
    path,
    keywords,
    image,
    index = true,
    follow = true,
  } = seo;
</script>

<SvelteSeo
  title="{title} | Temporal"
  {description}
  {keywords}
  nofollow={!follow}
  noindex={!index}
  canonical="https://temporal.io{path}"
  openGraph={{
    url: `https://temporal.io${path}`,
    title,
    description,
    images: [
      {
        url: image ? image : 'https://temporal.io/images/open-graph/shiny.png',
        width: 1200,
        height: 628,
        alt: 'Temporal Open Graph Image',
        type: 'image/png',
      },
    ],
  }}
/>
